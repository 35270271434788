const audioFile = require('../assets/karlkoder.mp3');
import {startMatrix} from "./libs/matrix-rain";
import {Howl} from 'howler';

const startAudio = () => {
    const audio = new Howl({
        autoplay: false,
        loop: true,
        src: [audioFile]
    });

    const playButton = document.getElementById("playIcon");
    playButton.addEventListener("click", () => {
        if(!audio.playing()) {
            audio.play();
        }
        
        playButton.classList.add("play--started");
    });
};

export const App = () => {
    startAudio();
    startMatrix();
};

document.addEventListener("DOMContentLoaded", () => {
    new App();
});
